<mat-toolbar>
  
    <button routerLink="/dashboard" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>home</mat-icon>
    </button>
    <span><b>Delay Post v-{{version}}</b></span>


    <span class="header-spacer"></span>
    
    <p>{{ this.username }}</p>
    <button mat-icon-button (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>

</mat-toolbar>