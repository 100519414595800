import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

import { EditorDialogComponent } from '../editor-dialog/editor-dialog.component';
import { Post } from '../post';
import { PostService } from '../services/post.service';
import { NotificationService } from '../services/notification.service';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {
    post_id: string;
    project_id: number;
    post: Post;
    posts: Post[]; // TODO we should now a list of posts

    constructor(
        private postService: PostService,
        private notificationService: NotificationService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) { } 

    ngOnInit() {
        this.getPost();
        this.route.queryParams
            .subscribe(params => {
                this.project_id = params.project_id;
                if (!this.project_id) {
                    this.notificationService.notifyError("ERROR. Mising project id. \
                        You can't publish post without project id.");
                }
            });
    }

    getPost(): void {
        this.route.paramMap.subscribe(params => {
            this.post_id = params.get("post_id");
        });
        this.post = this.postService.getPost(this.post_id);
    }

    savePost(): void {
        if (this.project_id) {
            this.post.project_id = this.project_id;
        }
        this.postService.updatePost(this.post);
    }

    publishPost(): void {
        this.post.project_id = this.project_id;
        if (!this.project_id) {
            this.notificationService.notifyError("ERROR. Missing project id! Can't publish the post. \
                Don't forget to save the post.");
            return;
        }
        this.postService.publishPost(this.post);
        // TODO: check message from backend
        this.notificationService.notify("Post was published.");
        this.router.navigate(["/project", this.project_id]);
    }

    schedulePost(): void {
        const dialogRef = this.dialog.open(EditorDialogComponent, {
            width: '550px',
            height: '250px',
            data: this.post
        });

        dialogRef.afterClosed().subscribe(data => {
            if (data["timestamp"]) {
                this.post.publish_time = data["timestamp"].toString();
                if (this.project_id) {
                    this.post.project_id = this.project_id;
                } else {
                    this.notificationService.notifyError("ERROR. Missing project id! Can't schedule the post. \
                        Don't forget to save the post.");
                    return;
                }
                this.post.disable_notification = data["disable_notification"];
                // TODO: check message from backend
                this.postService.schedulePost(this.post);
                this.notificationService.notify("Post was successfully scheduled.");
                this.router.navigate(["/project", this.project_id]);
            } else {
                this.notificationService.notifyError("Error. Date or time is missing. Couldn't publish post.");                
            }
        });
    }

    movePostToTimetable(): void {
        if (this.project_id) {
            this.post.project_id = this.project_id;
        }

        this.postService.timetablePost(this.post);
        this.router.navigate(["/project", this.project_id]);
    }

    deletePost(): void {
        // TOO: check
        this.postService.deletePost(this.post);
        this.router.navigate(["/project", this.project_id]);
    }
}
