<div class="mat-app-background basic-container">
  <mat-card class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>{{ post.title }}</mat-card-title>
      <mat-card-subtitle>
        <a href="{{ post.url }}"><mat-icon>link</mat-icon></a>
      </mat-card-subtitle>
    </mat-card-header>
    
    <mat-card-content>
      <div *ngFor="let attachment of post.attachments">
        <div *ngIf="attachment.is_video; then thenIsVideoContent else elseImageGifContent"></div>
        <ng-template #thenIsVideoContent>
          <a href="{{ attachment.url }}">{{ attachment.url }}</a>
        </ng-template>
        <ng-template #elseImageGifContent>
          <img mat-card-image src="{{ attachment.url }}" alt="{{ attachment.name }}">
        </ng-template>

        <div *ngIf="attachment.is_image">
          <mat-icon>image</mat-icon>
        </div>
        <div *ngIf="attachment.is_gif">
          <mat-icon>gif</mat-icon>
        </div>
        <div *ngIf="attachment.is_video">
          <mat-icon>videocam</mat-icon>
        </div>
      </div>
    </mat-card-content>
    <mat-tab-group>
      <mat-tab label="Translation">
        <div layout="row">
          <p>
            {{ post.text }}
          </p>
          <mat-form-field class="textarea-full-width">
            <mat-label>Перевод на русский язык</mat-label>
            <textarea [(ngModel)]="post.traslatedText" matInput #message maxlength="1024" placeholder="Прекрасный русский язык."></textarea>
            <mat-hint align="end">{{message.value.length}} / 1024</mat-hint>
          </mat-form-field>
        </div>
        <div layout="row" layout-align="end end">
          <button mat-stroked-button (click)="savePost()" color="primary">Save</button>
        </div>
      </mat-tab>
      <mat-tab label="Information">
        <p>id: {{post.post_id}}</p>
        <p>project_id: {{project_id}}</p>
        <p>post.project_id: {{post.project_id}}</p>
        <p>Stage: {{post.stage}}</p>
        <p>likes: {{post.likes}}</p>
        <p>Uploaded at: {{post.uploaded_at}}</p>
        <p>Publish timestamp: {{post.publish_time}}</p>
        <p *ngFor="let attachment of post.attachments">
          attachment url {{ attachment.url }}
          is_image: {{ attachment.is_image }}
          is_video: {{ attachment.is_video }}
          is_gif: {{ attachment.is_gif }}
        </p>
      </mat-tab>
      <mat-tab label="Comments">
        <p>There are no plans adding comments in the near future</p>
      </mat-tab>
    </mat-tab-group>

    <mat-card-actions>
      <button mat-button (click)="deletePost()"><mat-icon>delete</mat-icon></button>
      <button mat-button (click)="schedulePost()"><mat-icon>schedule</mat-icon></button>
      <button mat-button (click)="movePostToTimetable()"><mat-icon>calendar_today</mat-icon></button>
      <button mat-button (click)="publishPost()"><mat-icon>send</mat-icon></button>
    </mat-card-actions>
  </mat-card>
</div>
