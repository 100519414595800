import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User, UserLogIn } from 'src/app/_models/user';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private apiUrl = environment.apiUrl;

    private userSubject: BehaviorSubject<User>;
    private user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public getUser(): Observable<User> {
        return this.user;
    }

    login(userLogIn: UserLogIn) {
        return this.http.post<User>(`${this.apiUrl}/user/authenticate`, userLogIn)
            .pipe(
                tap(_ => _ )
            ).subscribe(user => {
                localStorage.setItem('token', user.token);
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                this.router.navigate(['dashboard']);
            });
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        localStorage.removeItem('token'); // Old item for token
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }

    getToken() {
        return localStorage.getItem('token');
    }
}