import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { UserLogIn } from '../_models/user';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    hide = true; // TEST
    form: FormGroup
    constructor(
        private _api : AccountService,
        private router: Router,
        public fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    login(){
        var userLogIn = new UserLogIn();
        userLogIn.username = this.form.value["username"];
        userLogIn.password = this.form.value["password"];
        this._api.login(userLogIn);
    }

}