<div class="login-card">
    <form [formGroup]='form' (ngSubmit)='login()'>
        <mat-form-field class="mat-form-field" hintLabel="Max 10 characters" appearance="fill">
            <mat-label>Enter your username</mat-label>
            <input  matInput  type="text" #input maxlength="20"  formControlName='username'>
            <mat-hint align="end">{{input.value?.length || 0}}/20</mat-hint>
        </mat-form-field>

        <mat-form-field class="mat-form-field" appearance="fill">
            <mat-label>Enter your password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName='password'>
        </mat-form-field>

        <button mat-button color="primary" type ='submit' [disabled]='!this.form.valid'> 
            Login 
        </button> 
    </form>
</div>
