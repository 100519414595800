import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user';
import { AccountService } from '../services/account.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    public username: string;
    public version: string = environment.build;

    constructor(
        private _accountService : AccountService
    ) { 
        this._accountService.getUser()
            .subscribe(
                user => {
                    if (user) {
                        this.username = user.username;
                    }
                }
            );
    }

    logout() {
        this.username = null;
        this._accountService.logout();
    }

    ngOnInit() {
    }

}
