import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditorComponent } from './editor/editor.component'
import { ProjectComponent } from './project/project.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { AuthGuardService } from './services/auth-guard.service';


const routes: Routes = [
    { path: '',   redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuardService] },
    { path: 'project/settings/:project_id', component: ProjectSettingsComponent, canActivate:[AuthGuardService] },
    { path: 'project/:project_id', component: ProjectComponent, canActivate:[AuthGuardService] },
    { path: 'editor/:post_id', component: EditorComponent, canActivate:[AuthGuardService] },
  

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
