import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Project, ProjectBase, Source, SourceBase } from '../project';
import { TimetableItem, TimetableItemBase } from '../project';
import { DateAdapter } from '@angular/material/core';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) { 
    }

    createSource(project_id: number, newSource: SourceBase) {
        return this.http.post<Source>(`${this.apiUrl}/project/${project_id}/create/source`, newSource)
            .pipe(
                //tap(_ => console.log('Creating source.'))
            );
    }

    createTabletimeItem(project_id: number, newTabletimeItem: TimetableItemBase) {
        return this.http.post<TimetableItem>(`${this.apiUrl}/timetable/${project_id}/create`, newTabletimeItem)
            .pipe(
                //tap(_ => console.log('Creating timetable item'))
            );
    }
    
    getProject(id: string) {
        return this.http.get<Project>(`${this.apiUrl}/project/${id}`)
            .pipe(
                //tap(_ => console.log('Get project.'))
            );
    }

    getProjects() {
        return this.http.get<Project []>(`${this.apiUrl}/projects`)
            .pipe(
                //tap(_ => console.log('Get list of projects.'))
            );
    }

    createProject(newProject: ProjectBase) {
        return this.http.post<Project>(`${this.apiUrl}/project`, newProject)
            .pipe(
                //tap(_ => console.log('Creating project.'))
            );
    }

    updateSource() {

    }

    deleteSource() {

    }

    fetchSource() {

    }

    // TODO: Temporary method; I'm lasy to make it right as for now
    getUsername(id: string): string {
        var fake_db = {
            '4': 'phill',
            '7': 'alex',
            '8': 'emil'
        };
        return fake_db[id];
    }

}
