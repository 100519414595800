import { Injectable } from '@angular/core';
import {Component} from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    durationInSeconds: number = 30;

    constructor(private _snackBar: MatSnackBar) { }

    notify(message: string): void {
        message = message + " ✅";
        this._snackBar.open(message, 'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
        });
    }

    // TODO: change style; change message of colors
    notifyError(message: string): void {
        message = "❌❌❌ " + message;
        this._snackBar.open(message, 'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: this.durationInSeconds * 1000,
        });
    }
}