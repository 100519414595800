import { Component, OnInit, Inject } from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { logging } from 'protractor';

@Component({
    selector: 'app-editor-dialog',
    templateUrl: './editor-dialog.component.html',
    styleUrls: ['./editor-dialog.component.css']
})
export class EditorDialogComponent implements OnInit {
    selectedDate: string;
    selectedHour: number;
    selectedMinute: number;
    selectedDisableNotification: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<EditorDialogComponent>,
    ) { }
        
    ngOnInit(): void {
    }

    cancel(): void {
        this.dialogRef.close();
    }

    schedule() {
        var publishTime = new Date(this.selectedDate);
        publishTime.setHours(this.selectedHour);
        publishTime.setMinutes(this.selectedMinute);
        
        let timestamp: number;
        if (publishTime) {
            timestamp = publishTime.getTime() / 1000;
        }
        
        return { "timestamp" : timestamp, "disable_notification": this.selectedDisableNotification };
    }
}
