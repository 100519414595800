export class Attachment {
    id: number;
    name: string;
    url: string;
    is_image: boolean;
    is_video: boolean;
    is_gif: boolean;
}

export class Post {
    id: number;
    post_id: string;
    source_id: string;
    project_id: number;
    stage: string;
    publish_time: string;
    title: string;
    snippet: string; // snippet by using ML, or just a description
    text: string; // Main body of post
    traslatedText: string;
    quillText: string; // Not implemented
    url: string;
    likes: number;
    uploaded_at: string;
    disable_notification: boolean = false;
    comments: string[]; // Not implemented
    attachments: Attachment[];
    nsfw: boolean;
    published_by: number;
}