export class SourceBase {
    name: string;
    enable: boolean;
    description: string;
    url: string;
    parser: string;
    amount: number;

    constructor(name: string, description: string, 
        url: string, parser: string, amount: number) {
        this.name = name;
        this.description = description;
        this.enable = true; // TODO: add to constructor
        this.url = url;
        this.parser = parser;
        this.amount = amount;
    }
}

export class Source extends SourceBase {
    id: number;
}

export class TimetableItemBase {
    day: number;
    hour: number;
    minute: number;

    constructor(day: number, hour: number, minute: number) {
        this.day = day;
        this.hour = hour;
        this.minute = minute;
    }
}

export class TimetableItem extends TimetableItemBase {
    id: number;
}

export class ProjectBase {
    name: string;
    description: string;
    telegram_channel_id: string;

    constructor(name: string, description: string, telegram_channel_id: string) {
        this.name = name;
        this.description = description;
        this.telegram_channel_id = telegram_channel_id;
    }
}

export class Project extends ProjectBase {
    id: number;
    sources: Source[];
    timetable: TimetableItem[];
}
