<h1 mat-dialog-title>Scheduling post:</h1>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input [(ngModel)]="selectedDate" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> 
    <mat-form-field class="time-form"  appearance="fill">
        <mat-label>Hours</mat-label>
        <mat-select [(ngModel)]="selectedHour" required>
            <mat-option *ngFor="let number of [].constructor(24); let i = index;"
                [value]="i">
                {{ i }} 
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field  class="time-form" appearance="fill">
        <mat-label>Minutes</mat-label>
        <mat-select [(ngModel)]="selectedMinute" required>
            <mat-option *ngFor="let number of [].constructor(12); let i = index;"
                [value]="i*5">
                {{ i*5 }}
            </mat-option>
        </mat-select>
        <mat-hint>5 minutes step</mat-hint>
    </mat-form-field>
    <mat-slide-toggle [(ngModel)]="selectedDisableNotification" class="silent-form">Disable notification</mat-slide-toggle>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Cancel</button>
    <!-- TODO: need to check this part. Add logging to schedule function and you will see that 
    the function is called a lot of times, it is a right behaviour? -->
    <button mat-button [mat-dialog-close]="schedule()" cdkFocusInitial>Schedule</button>
</div>
