import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { ConfigService } from '../services/config.service';
import { Project, ProjectBase, Source, SourceBase } from '../project';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    projects: Project[];
    displayedColumns: string[] = ['name', 'description', 'url', 'parser', 'amount'];
    constructor(
        private configService: ConfigService,
        private route: ActivatedRoute
    ) { 
        this.projects = [];
    }

    ngOnInit(): void {
        this.configService.getProjects()
            .subscribe(projects => this.projects = projects);
    }

    createSource(index: number, name: string, description: string,
        url: string, parser: string, amount: number): void {
        this.configService.createSource(this.projects[index].id,
            new SourceBase(name, description, url, parser, amount))
            .subscribe(source => this.projects[index].sources.push(source));
    }

    createProject(name: string, description: string, telegram_channel_id: string): void { 
        this.configService.createProject(new ProjectBase(name, description, telegram_channel_id))
            .subscribe(newProject => this.projects.push(newProject));
    }
}
