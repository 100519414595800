<div class="dashboard-main">
    <h3> Projects: </h3>
    <mat-accordion class="example-headers-align" *ngFor="let project of projects; index as i;">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
            <mat-panel-title>
                <a routerLink="/project/{{project.id}}">{{ project.name }}</a>
                </mat-panel-title>
                <mat-panel-title>
                    {{ project.telegram_channel_id }}
                </mat-panel-title>
                <mat-panel-description>
                    <a routerLink="/project/settings/{{project.id}}"><mat-icon>settings</mat-icon></a>
                </mat-panel-description>
            </mat-expansion-panel-header>

        </mat-expansion-panel>
    </mat-accordion>


    <mat-divider></mat-divider>


    <mat-accordion class="example-headers-align">
        <mat-expansion-panel  hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Create new project
            </mat-panel-title>
            <mat-panel-description>
            Type project name and description
        
            </mat-panel-description>
        </mat-expansion-panel-header>
    
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input #newProjectName matInput>
        </mat-form-field>
    
        <mat-form-field>
            <mat-label>Description</mat-label>
            <input #newProjectDescription matInput>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Telegram channel</mat-label>
            <input #newProjectTelegramChannel matInput>
        </mat-form-field>

        <mat-action-row>
            <button mat-button color="primary" 
                (click)="createProject(newProjectName.value, newProjectDescription.value, 
                                       newProjectTelegramChannel.value)">Create</button>
        </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</div>