<div class="tab-group-form">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="postStageTabChanged($event)">
        <mat-tab
            *ngFor="let stageTab of stageTabs"
            [label]="getLabelName(stageTab)" >
        </mat-tab>
    </mat-tab-group>
</div>

<div class="main">
    <div class="container">
        <div *ngFor="let post of posts">
            <div *ngIf="post.stage==stage">
                <div class="item">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-subtitle>
                                <div *ngIf="post.publish_time">
                                    {{ post.publish_time + "000" |  date: 'MMM d, y, h:mm:ss a' }}
                                </div>
                            
                                <div *ngIf="post.publish_time==null">
                                    {{ post.likes }} likes
                                </div>
                                
                                <div *ngIf="post.disable_notification==true">
                                    <mat-icon>notifications_off</mat-icon>
                                </div>
                                <a href="{{ post.url }}"><mat-icon>link</mat-icon></a> 
                            </mat-card-subtitle>
                            <div *ngIf="post.published_by && post.stage!='queue'">
                                <mat-chip-list aria-label="Fish selection">
                                    <mat-chip color="accent" selected>{{ getPostPublisherName(post.published_by) }}</mat-chip>
                                </mat-chip-list>
                            </div>

                        </mat-card-header>
                        <mat-card-content>
                            <div *ngFor="let attachment of post.attachments">
                                <div *ngIf="attachment.is_video; then thenIsVideoContent else elseImageGifContent"></div>
                                <ng-template #thenIsVideoContent>
                                    <mat-icon>videocam</mat-icon> <a href="{{ attachment.url }}">{{ attachment.url }}</a>
                                </ng-template>
                                <ng-template #elseImageGifContent>
                                  <img mat-card-image src="{{ attachment.url }}" alt="{{ attachment.name }}">
                                </ng-template>
                            </div>
    
                                <ng-container *ngIf="post.traslatedText; then translationText; else originalText">
                                </ng-container>
                            
                                <ng-template #translationText>
                                    <p>{{ post.traslatedText }}</p>
                                </ng-template>
                            
                                <ng-template #originalText>
                                    <p>{{ post.title }}</p>
                                </ng-template>                                  
                        </mat-card-content>
                        <mat-card-actions>
                            <button mat-button (click)="deletePost(post)"><mat-icon>delete</mat-icon></button>
                            <button mat-button [routerLink]="['/editor', post.post_id]"
                                                [queryParams]="{ project_id: project_id }" >
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>

