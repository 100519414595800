<div class="main">
    <h2> {{ this.project_name }} </h2>
    <br>
    <h3> Timetable </h3>
    <div *ngIf="this.project">
        <div class="timetable-form">
            <mat-grid-list cols="7" rowHeight="300px">
                <mat-grid-tile *ngFor="let number of [].constructor(7); let day = index;">
                    <mat-grid-tile-header>
                        {{ this.displayedWeekDay[day] }}
                    </mat-grid-tile-header>
                    <mat-list role="list">
                        <div *ngFor="let item of this.project.timetable" >
                            <div *ngIf="item.day==day">
                                <mat-list-item role="listitem">
                                    {{ getTime(item.hour, item.minute)}}
                                </mat-list-item>
                            </div>
                        </div>
                    </mat-list>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>

    <h4>Create slot</h4>
    <div>
        <mat-form-field class="day-form"  appearance="fill">
            <mat-label>Day</mat-label>
            <mat-select [(ngModel)]="this.selectedDay" required>
                <mat-option *ngFor="let day of this.displayedWeekDay; let i = index;"
                    [value]="i">
                    {{ day }} 
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field class="hour-form"  appearance="fill">
            <mat-label>Hour</mat-label>
            <mat-select [(ngModel)]="selectedHour" required>
                <mat-option *ngFor="let number of [].constructor(24); let i = index;"
                    [value]="i">
                    {{ i }} 
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field  class="minute-form" appearance="fill">
            <mat-label>Minute</mat-label>
            <mat-select [(ngModel)]="selectedMinute" required>
                <mat-option *ngFor="let number of [].constructor(12); let i = index;"
                    [value]="i*5">
                    {{ i*5 }}
                </mat-option>
            </mat-select>
            <mat-hint>5 minutes step</mat-hint>
        </mat-form-field>
    
        <button mat-button color="primary" (click)="createTabletimeItem()">
            Add
        </button>
    </div>

    <h3> Sources </h3>

    <div *ngIf="this.project">
        <table mat-table [dataSource]="this.project.sources" class="mat-elevation-z8">
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Id Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
    
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
    
            <!-- Amount Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
            </ng-container>
    
            <!-- Parser Column -->
            <ng-container matColumnDef="parser">
                <th mat-header-cell *matHeaderCellDef> Parser </th>
                <td mat-cell *matCellDef="let element"> {{element.parser}} </td>
            </ng-container>
    
            <!-- Url Column -->
            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef> Url </th>
                <td mat-cell *matCellDef="let element"> {{element.url}} </td>
            </ng-container>
                
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-panel-title><h4>Create source</h4></mat-panel-title>
    <mat-form-field class="name-form">
        <mat-label>Name</mat-label>
        <input #newSourceName matInput>
    </mat-form-field>        

    <mat-form-field class="parser-form">
        <mat-label>Parser</mat-label>
        <input #newSourceParser matInput value="reddit">
    </mat-form-field>    
    <mat-form-field class="amount-form">
        <mat-label>Amount</mat-label>
        <input #newSourceAmount matInput value=25>
    </mat-form-field>
    <mat-form-field class="url-form">
        <mat-label>Url</mat-label>
        <input #newSourceUrl matInput>
    </mat-form-field>
    
    <button mat-button color="primary" 
    (click)="createSource(newSourceName.value, newSourceUrl.value, newSourceParser.value, newSourceAmount.value)">Create</button>
</div>
