import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ConfigService } from '../services/config.service';
import { NotificationService } from '../services/notification.service';
import { Project } from '../project';
import { TimetableItemBase, TimetableItem } from '../project';
import { Source, SourceBase } from '../project';

@Component({
    selector: 'app-project-settings',
    templateUrl: './project-settings.component.html',
    styleUrls: ['./project-settings.component.css']
})
export class ProjectSettingsComponent implements OnInit {
    id: string;
    project_name: string;
    project: Project;
    displayedColumns: string[] = ['id', 'name', 'parser', 'amount', 'url'];
    displayedWeekDay = ["Monday", "Tuesday", "Wednesday",
        "Thursday", "Friday", "Saturday", "Sunday"];

    selectedDay: number;
    selectedHour: number;
    selectedMinute: number;

    constructor(
        private configService: ConfigService,
        private notificationService: NotificationService,
        private route: ActivatedRoute
    ) {

    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.id = params.get("project_id");
        });
        this.configService.getProject(this.id)
            .subscribe(project => {
                this.project = project;
                this.project_name = project.name;
                // console.log(this.project);
            });
    }

    createSource(
        name: string, 
        url: string,
        parser: string,
        amount: number): void {
        this.configService.createSource(
            this.project.id,
            new SourceBase(name, "", url, parser, amount))
        .subscribe(source => {    
            this.project.sources.push(source);
            this.notificationService.notify("Successfully created source.")
        });
    }

    // Timetable
    createTabletimeItem(): void {
        if (this.selectedDay == null || this.selectedHour == null || this.selectedMinute == null) {
            this.notificationService.notifyError("ERROR: Day or hour \
                or minute are missing. Please select all fields."
            );
        }

        this.configService.createTabletimeItem(this.project.id,
            new TimetableItemBase(this.selectedDay, this.selectedHour, this.selectedMinute))
            .subscribe(item => {
                this.project.timetable.push(item);
            });
    }

    getDay(day: number): string {
        switch (day) {
            case 0:
                return "Monday";
            case 1:
                return "Tuesday";
            case 2:
                return "Wednesday";
            case 3:
                return "Thursday";
            case 4:
                return "Friday";
            case 5:
                return "Saturday";
            case 6:
                return "Sunday";
            default: 
                return "None";
        }
    }

    getTime(hour: number, minute: number): string {
        let s_hour: string = "0" + String(hour);
        let s_minute: string = "0" + String(minute);
        return s_hour.slice(-2) + ":" + s_minute.slice(-2);
    }

}
