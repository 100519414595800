import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MatTabChangeEvent } from '@angular/material/tabs';

import { PostService } from '../services/post.service';
import { ConfigService } from '../services/config.service';
import { NotificationService } from '../services/notification.service';

import { Post } from '../post';
import { Project } from '../project';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
    stageTabs = [
        { name: "Queue", stage: "queue", amount: null },
        { name: "Timetable", stage: "timetable", amount: null },
        { name: "Scheduled", stage: "scheduled", amount: null },
        { name: "Error", stage: "error", amount: null },
    ];

    project_id: string;
    project: Project;
    posts: Post[];
    stage: string;

    panelOpenState = false;

    constructor(
        private postService: PostService,
        private configService: ConfigService,
        private notificationService: NotificationService,
        private route: ActivatedRoute
    ) {
        this.posts = [];
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.project_id = params.get("project_id");
        });
        this.stage = "queue";
        this.postService.getPosts(this.project_id)
            .subscribe(data => {
                var queuePosts: Post[] = [];
                var scheduledPosts: Post[] = [];
                var timetablePosts: Post[] = [];
                var errorPosts: Post[] = [];
                data.forEach((postRecord) => {
                    if (postRecord.stage == "queue") {
                        queuePosts.push(postRecord);
                    } else if (postRecord.stage == "scheduled") {
                        scheduledPosts.push(postRecord);
                    } else if (postRecord.stage == "timetable") {
                        timetablePosts.push(postRecord);
                    }  else if (postRecord.stage == "error") {
                        errorPosts.push(postRecord);
                    }                
                });
                this.stageTabs[0]["amount"] = queuePosts.length;
                this.stageTabs[1]["amount"] = timetablePosts.length;
                this.stageTabs[2]["amount"] = scheduledPosts.length;
                this.stageTabs[3]["amount"] = errorPosts.length;

                this.sortQueue(queuePosts);
                this.sortScheduled(scheduledPosts);
                this.posts = this.posts.concat(
                    queuePosts,
                    scheduledPosts,
                    timetablePosts,
                    errorPosts
                );
            });

        this.configService.getProject(this.project_id)
            .subscribe(data => {
                this.project = data;
            });
    }

    deletePost(post: Post): void {
        const index = this.posts.indexOf(post);
        if (index > -1) {
            this.posts.splice(index, 1);
        }
        // TODO: make publish time as null for deleted post 
        this.postService.deletePost(post);
    }

    editPost(): void {

    }

    getPostPublisherName(id: string): string {
        return this.configService.getUsername(id);
    }

    postStageTabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.stage = this.stageTabs[tabChangeEvent.index]["stage"];
    }

    sortQueue(posts: Post[]): void {
        posts = posts.sort((left, right) => {
            if (left.likes < right.likes) {
                return 1;
            }
        
            if (left.likes > right.likes) {
                return -1;
            }
            return 0;
        });
    }

    sortScheduled(posts: Post[]): void {
        posts = posts.sort((left, right) => {
            if (left.publish_time > right.publish_time) {
                return 1;
            }
        
            if (left.publish_time < right.publish_time) {
                return -1;
            }
            return 0;
        });        
    }

    getLabelName(stageTab: any): string {
        let name: string = stageTab["name"];
        if (stageTab["amount"]) {
            name += " (" + String(stageTab["amount"]) + ")";
        }
        return name;
    }
}
