import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';

import { Post, Attachment } from '../post';
import { AccountService } from './account.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PostService {
    private user_id: string;
    private apiUrl = environment.apiUrl;
    private apiLambdaUrl = environment.apiLambdaUrl;

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(
        private http: HttpClient,
        private _accountService: AccountService,
    ) { 
        this._accountService.getUser()
        .subscribe(
            user => {
                if (user) {
                    this.user_id = user.id;
                }
            }
        );
    }

    getPost(id: string): Post {
        const url = `${this.apiUrl}/post/${id}`;
    
        var post: Post = new Post();
        var response = this.http.get<Post>(url)
            .subscribe(data => {
                // TODO: figure out why this doesn't work: post = data;
                post.id = data.id;
                post.post_id = data.post_id;
                post.source_id = data.source_id;
                post.stage = data.stage;
                post.publish_time = data.publish_time;
                post.title = data.title;
                post.snippet = data.snippet;
                post.text = data.text;
                post.traslatedText = data.traslatedText;
                post.quillText = data.quillText;
                post.url = data.url;
                post.likes = data.likes;
                post.uploaded_at = data.uploaded_at;
                post.disable_notification = data.disable_notification;
                post.comments = data.comments;
                post.nsfw = data.nsfw;
                post.attachments = data.attachments;
                post.published_by = data.published_by;
            }
        );
        return post;
    }

    getPosts(project_id: string){
        return this.http.get<Post []>(`${this.apiUrl}/project/${project_id}/posts`)
            .pipe(
                tap(_ => console.log('Get list of posts for project.'))
            );

    }

    getPostsForSource(source_id: string){
        const url = `${this.apiUrl}/source/${source_id}/posts`;
        var posts: Post[] = [];
        var response = this.http.get<Post []>(url)
            .subscribe(data => {
                data.forEach((postRecord) => {
                    posts.push(postRecord);
                });
            }
        );

        return posts;
    }

    updatePost(post: Post) {
        // console.log("Updating post");
        const url = `${this.apiUrl}/post/update`;
        var response = this.http.post(url, post)
            .subscribe();
    }

    schedulePost(post: Post) {
        // console.log("PostService: Scheduling post");
        var params = new HttpParams()
            .set("user_id", this.user_id);
        
        post.stage = "scheduled";
        const url = `${this.apiUrl}/post/update`;
        var response = this.http.post(url, post, {params: params})
            .subscribe();
    }
    
    timetablePost(post: Post) {
        // console.log("PostService: Timetable post");
        var params = new HttpParams()
            .set("user_id", this.user_id);
        
            post.stage = "timetable";
        const url = `${this.apiUrl}/post/update`;
        var response = this.http.post(url, post, {params: params})
            .subscribe();
    }

    publishPost(post: Post) {
        // console.log("Publishing post");
        var params = new HttpParams()
            .set("user_id", this.user_id);

        const url = `${this.apiUrl}/post/publish`;
        var response = this.http.post(url, post, {params: params})
            .subscribe();
    }

    deletePost(post: Post) {
        // console.log("Deleting post");
        post.stage = "deleted";
        this.updatePost(post);
    }
}
